.link-copy-block {
	display: flex;
	width: 264px;
	height: 35px;
	max-width: 98vw;
	flex-direction: row;
	align-items: center;
	border: 1px solid black;
	border-radius: 5px;
	gap: 5px;
	cursor: pointer;
}

.link-copy-block span {
	flex: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-left: 10px;
}

.link-copy-block div {
	flex-shrink: 0;
	width: 25px;
	height: 25px;
	background-color: black;
	margin-right: 5px;
}