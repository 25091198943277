.settings-main {
    width: 50%;
    max-width: 500px;
    margin: 20px auto 0px;
    font-size: medium;
}

.settings-main header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.settings-main header img {
    height: 50px;
    cursor: pointer;
}

@media (max-width: 750px) {
    .settings-main {
        width: 90%;
    }
}

.settings-main select {
    width: 100%;
    margin: 10px;
    font-size: medium;
    outline-style: none;
    background-color: var(--bg);
    color: var(--tc);
    padding: 1.5%;
    margin: 0;
    border-radius: 5px;
}

.settings-main input {
    width: 100%;
    font-size: medium;
    outline-style: none;
    background-color: var(--bg);
    color: var(--tc);
    padding: 1.5%;
    border-radius: 5px;
    border-width: 1px;
	border-style: solid;
}
