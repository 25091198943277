.modal--background {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;

	background-color: rgba(0, 0, 0, 0.4);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.modal--main {
	width: 50%;
    max-width: 400px;
    font-size: medium;
    background-color: white;
    border-color: #6eb4ff;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    min-height: 50px;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
}

.modal--header {
	display: block;
    border-bottom: gray 1px solid;
    display: flex;
    flex-direction: row;
    align-items: stretch;
	color: #575757;
	padding: 5px 10px;
	font-size: 1.5em;
    font-weight: bolder;
}

.modal--body {
	flex: 1;
    padding: 10px 10px;
    font-size: 1.5em;
    line-height: 1.5;
    color: #575757;
}

.modal--footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px;
}

.modal--footer button {
	box-shadow: none;
	outline: none;
	border: none;

	margin-left: 10px;
	text-decoration: none;
	color: white;
	cursor: pointer;

	background-color: #007bff;
    border-radius: 7px;
    padding: 7px;
    font-size: medium;
    width: max-content;

	min-width: 100px;
}

.modal--footer buuton:focus {
	outline: none;
}