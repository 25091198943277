.tiler-loader-main {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	flex: 1;
	gap: 20px;
}

.tiler-loader {
	--cell-size: 64px;
	--cell-spacing: 1px;
	--border-width: 1px;
	--cells: 4;
	--total-size: calc(var(--cells) * (var(--cell-size) + 2 * var(--cell-spacing)));
	display: flex;
	flex-wrap: wrap;
	width: var(--total-size);
	height: var(--total-size);
}
.tiler-loader > .cell {
	--cell-color: white;
	flex: 0 0 var(--cell-size);
	margin: var(--cell-spacing);
	background-color: transparent;
	box-sizing: border-box;
	border: var(--border-width) solid var(--cell-color);
	animation: 1.5s ripple ease infinite;
}
.tiler-loader > .cell.d-1 {
	animation-delay: 100ms;
}
.tiler-loader > .cell.d-2 {
	animation-delay: 200ms;
}
.tiler-loader > .cell.d-3 {
	animation-delay: 300ms;
}
.tiler-loader > .cell.d-4 {
	animation-delay: 400ms;
}
.tiler-loader > .cell.d-5 {
	animation-delay: 500ms;
}
.tiler-loader > .cell.d-6 {
	animation-delay: 600ms;
}
.tiler-loader > .cell:nth-child(1) {
	--cell-color: #d4aee0;
}
.tiler-loader > .cell:nth-child(2) {
	--cell-color: #8975b4;
}
.tiler-loader > .cell:nth-child(3) {
	--cell-color: #64518a;
}
.tiler-loader > .cell:nth-child(4) {
	--cell-color: #565190;
}
.tiler-loader > .cell:nth-child(5) {
	--cell-color: #44abac;
}
.tiler-loader > .cell:nth-child(6) {
	--cell-color: #2ca7d8;
}
.tiler-loader > .cell:nth-child(7) {
	--cell-color: #1482ce;
}
.tiler-loader > .cell:nth-child(8) {
	--cell-color: #05597c;
}
.tiler-loader > .cell:nth-child(9) {
	--cell-color: #b2dd57;
}
.tiler-loader > .cell:nth-child(10) {
	--cell-color: #57c443;
}
.tiler-loader > .cell:nth-child(11) {
	--cell-color: #05b853;
}
.tiler-loader > .cell:nth-child(12) {
	--cell-color: #19962e;
}
.tiler-loader > .cell:nth-child(13) {
	--cell-color: #fdc82e;
}
.tiler-loader > .cell:nth-child(14) {
	--cell-color: #fd9c2e;
}
.tiler-loader > .cell:nth-child(15) {
	--cell-color: #d5385a;
}
.tiler-loader > .cell:nth-child(16) {
	--cell-color: #911750;
}
  
@keyframes ripple {
	0% {
	  	background-color: transparent;
	}
	30% {
	  	background-color: var(--cell-color);
	}
	60% {
	  	background-color: transparent;
	}
	100% {
		background-color: transparent;
	}
}