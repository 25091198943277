.game-page-main {
    flex: 1;
    display: grid;
    overflow: hidden;
}

.game-header {
    grid-area: header;
}

.game-field-main {
    grid-area: field;
    overflow: hidden;
}

.color-picker-main-0 {
    grid-area: color-picker-1;
    overflow: hidden;
}

.color-picker-main-1 {
    grid-area: color-picker-2;
    overflow: hidden;
}

@media (orientation: portrait) {
    .game-page-main {
        padding-left: 1vw;
        padding-right: 1vw;
        padding-top: 1vw;
        padding-bottom: 4vw;

        grid-template-areas: 
            "header header header"
            "field field field"
            "color-picker color-picker color-picker"
        ;
        grid-template-columns: min(14vw, 50px) 1fr min(14vw, 50px);
        grid-template-rows: min(14vw, 50px) 1fr 50px;
        gap: 10px;
    }

    .game-header {
        height: min(14vw, 50px);
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .game-field-heder-color {
        width: min(14vw, 50px);
        height: min(14vw, 50px);
        border-radius: 25%;
    }

    .game-field-heder-color-1 {
        width: min(14vw, 50px);
        height: min(14vw, 50px);
        border-radius: 20%;
    }

    .game-field-main {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .game-field {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .game-field svg {
        max-width: 100%;
        height: 100%;
    }

    .color-picker-main-0 {
        width: 100%;
        height: 50px;
        overflow: auto;
        grid-area: color-picker;
    }

    .color-picker-main-1 {
        width: 100%;
        height: 50px;
        overflow: auto;
        grid-area: color-picker;
    }

    .color-picker {
        display: flex;
        height: 50px;
        width: min-content ;
        flex-direction: row;
        align-items: center;
    }

    .color-button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: 8px;
    }
}

@media (orientation: landscape) {
    .game-page-main {
        margin-left: 0.5vw;
        margin-right: 0.5vw;
        padding-top: 10px;
        padding-bottom: 10px;
        grid-template-areas: 
            "header header header"
            "color-picker-1 field color-picker-2"
            "color-picker-1 field color-picker-2"
        ;
        grid-template-columns: 50px 1fr 50px;
        grid-template-rows:  50px 1fr 50px;
        gap: 20px;
        
    }

    .game-header {
        height: 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .game-field-heder-color {
        width: 50px;
        height: 50px;
        border-radius: 25%;
    }

    .game-field-heder-color-1 {
        width: 50px;
        height: 50px;
        border-radius: 20%;
    }

    .game-field-main {
        height: 100%;
        width: 100%;
    }

    .game-field {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .game-field svg {
        max-width: 100%;
        height: 100%;
    }

    .color-picker-main-0, .color-picker-main-1 {
        height: 100%;
        width: 50px;
        overflow: auto;
    }

    .color-picker {
        display: flex;
        width: 50px;
        flex-direction: column;
        align-items: center;
    }

    .color-button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-top: 8px;
    }
}