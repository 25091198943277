.about-main {
    width: 50%;
    max-width: 500px;
    margin: 20px auto 0px;
    font-size: medium;
}

@media (max-width: 750px) {
    .about-main {
        width: 90%;
    }
}

.about-main header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.about-main header img {
    height: 50px;
}