@import url(./icons.css);
@import url(./settings.css);
@import url(./home.css);
@import url(./about.css);
@import url(./game.css);
@import url(./modals.css);
@import url(./loader.css);
@import url(./copy.css);

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	  sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	--bg: #ffffff;
	--tc: #000000;
	height: 100vh;
	height: -webkit-full-available;
	/* height: 100%; */
	width: 100vw;
	/* overflow: hidden; */
}
  
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  	monospace;
}

#root {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}